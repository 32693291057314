import repository from "@/repositories/PaymentApiRepository";
import {HandlerMessageServerError} from "@/utils/Helpers";
import StrukturErrorService from "@/services/StrukturErrorService";

export default class PaymentApiService {
	constructor() {
		this.dataRepository = repository;
	}

	async transactionInqPost(nominal) {
		if (!nominal) {
			throw new Error("required nomnial");
		}

		const formData = {
			nominal: nominal,
		}

		try {
			return await this.dataRepository.postTransactionDepositInq(formData);
		} catch (error) {
			if (error.code >= 500) {
				HandlerMessageServerError()
			}
			const parsedError = StrukturErrorService.parseError(error);
			console.log(parsedError);
			throw parsedError;
		}
	}

	async transactionTrxPost(payload) {
		const {
			biller,
			channel_method_id,
			channel_method_category,
			transaction_id,
		} = payload

		if (!biller || !channel_method_id || !channel_method_category || !transaction_id) {
			throw new Error("body is not complete");
		}

		const formData = {
			biller: biller,
			channel_method_id: channel_method_id,
			channel_method_category: channel_method_category,
			transaction_id: transaction_id,
		}

		try {
			return await this.dataRepository.postTransactionDepositTrx(formData);
		} catch (error) {
			if (error.code >= 500) {
				HandlerMessageServerError()
			}
			const parsedError = StrukturErrorService.parseError(error);
			throw parsedError;
		}
	}

}
