import {
	ApiDeleteRequest,
	ApiGetRequest,
	ApiGetRequestKoperasiPayment,
	ApiNotification,
	ApiPostRequest,
	ApiPostRequestKoperasiPayment,
	ApiPutRequest,
} from "@/utils/Api";
import moment from "moment";
import {Message} from "element-ui";
import {
	formatNumberKoma,
	handleErrorInCooperative,
	tableOfWidth,
} from "@/services/internalService";
import {
	DUPLICATE_CALL_SERVER_MESSAGE,
	PAYMENT_SERVER_ERROR_MESSAGE,
	SERVER_ERROR_MESSAGE,
} from "@/services/messageService";
import PaymentApiService from "@/services/PaymentApiService";

const Payment = {
	namespaced: true,
	state: {
		data: {
			error: null,
			errors: [],
			account_bank: [],
			loading: false,
			wallet: {},
			channel: {},
			idTransaksi: "",
			chooseChannel: {},
			nominalValue: 0,
			showFormTopup: false,
			nominal: [
				{
					label: "Rp. 25.000,-",
					value: 25000,
				},
				{
					label: "Rp. 50.000,-",
					value: 50000,
				},
				{
					label: "Rp. 100.000,-",
					value: 100000,
				},
				{
					label: "Rp. 500.000,-",
					value: 500000,
				},
			],
			trxCheckout: {},
			mutasiPayment: [],
			historyMutasi: [],
			snapShootPayment: [],
			snapShootPaymentToday: {},
			bank: [],
			detail_bank: false,
			saldoBalance: 0,
			tarik_saldo_loading: false,
			tarik_saldo_inquery: {},
			tarik_saldo_error: null,
			tarik_saldo: [],
			filter_tarik_saldo: "",
			filter_bank_tarik_saldo: "",
			limit_tarik_saldo: 10,
			page_tarik_saldo: 1,
			total_tarik_saldo: 0,
			columns_topup: [],
			columns_topup_pelajar: [
				{
					prop: "select",
					label: "Check",
					type: "selection",
					minWidth: "100",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "name",
					label: "Nama",
					minWidth: "100",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "nisn_nik",
					label: "NIS / NISN",
					minWidth: "100",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "class_room",
					label: "Kelas",
					minWidth: "100",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "major",
					label: "Jurusan",
					minWidth: "100",
					"class-name": "body-14 col-black-2",
				},

				{
					prop: "wallet_id",
					label: "ID Wallet",
					minWidth: "100",
					"class-name": "body-14 col-black-2",
				},
			],
			columns_rekening: [
				{
					prop: "name",
					label: "Bank",
					minWidth: "100",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "bank_account_number",
					label: "No Rekening",
					minWidth: "100",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "bank_account_holder",
					label: "Nama Pemegang Rekening",
					minWidth: "100",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "aksi",
					label: "Aksi",
					minWidth: "50",
					"class-name": "body-14 col-black-2",
				},
			],
			limit_rekening: 10,
			page_rekening: 1,
			total_rekening: 0,
			filter_rekening: "",
			columns_tarik_saldo: [
				{
					prop: "created_at",
					label: "Detail",
					width: "200",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "cooperative.name",
					label: "Sekolah",
					minWidth: "200",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "bank_account_number",
					label: "Status",
					width: "140",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "bank_name",
					label: "Tujuan",
					minWidth: "180",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "amount",
					label: "Nominal",
					width: "160",
					"class-name": "body-14 col-black-2",
				},
			],
			columns_guru: [
				{
					prop: "select",
					label: "Check",
					type: "selection",
					minWidth: "100",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "name",
					label: "Nama",
					minWidth: "100",
					"class-name": "body-14 col-black-2",
					sortable: true,
				},
				{
					prop: "nisn_nik",
					label: "NIK",
					minWidth: "100",
					"class-name": "body-14 col-black-2",
					sortable: true,
				},
				{
					prop: "role",
					label: "Profesi",
					minWidth: "100",
					"class-name": "body-14 col-black-2",
					sortable: true,
				},
				{
					prop: "pelajaran",
					label: "Mapel",
					minWidth: "100",
					"class-name": "body-14 col-black-2",
					sortable: true,
				},
				{
					prop: "kelas_mengajar",
					label: "Kelas",
					minWidth: "100",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "wallet_id",
					label: "ID Wallet",
					minWidth: "100",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "aksi",
					label: "Aksi",
					minWidth: "50",
					"class-name": "body-14 col-black-2",
				},
			],
			columns_pelajar: [
				{
					prop: "select",
					label: "Check",
					type: "selection",
					minWidth: "100",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "name",
					label: "Nama",
					minWidth: "200",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "nisn_nik",
					label: "NIS / NISN",
					width: "200",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "class_room",
					label: "Kelas",
					width: "180",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "generation",
					label: "Angkatan",
					width: "100",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "wallet_id",
					label: "ID Wallet",
					width: "130",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "aksi",
					label: "Aksi",
					width: "80",
					"class-name": "body-14 col-black-2",
				},
			],
			column_preview_pelajar: [
				{
					prop: "name",
					label: "Nama",
					minWidth: "200",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "nisn_nik",
					label: "NIS / NISN",
					minWidth: "160",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "class_room",
					label: "Kelas",
					...tableOfWidth.class.minWidth,
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "wallet_id",
					label: "ID Wallet",
					width: "120",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "aksi",
					label: "Aksi",
					width: "90",
					"class-name": "body-14 col-black-2",
				},
			],
			columns_saldo: [
				{
					prop: "expand",
					label: "",
					type: "expand",
				},
				{
					prop: "transaction_id",
					label: "ID Trx",
					minWidth: "100",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "status",
					label: "Status",
					minWidth: "130",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "transaction_type",
					label: "Tipe",
					minWidth: "120",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "transaction_to_desc",
					label: "Deskripsi",
					minWidth: "140",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "nominal",
					label: "Nominal",
					width: "140",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "transaction_from",
					label: "Dari",
					minWidth: "90",
					"class-name": "body-14 col-black-2",
				},
				{
					prop: "transaction_to",
					label: "Ke",
					width: "140",
					"class-name": "body-14 col-black-2",
				},
				/*{
					prop: "cashwallet_id",
					label: "ID Wallet",
					width: "120",
					"class-name": "body-14 col-black-2",
				},*/
				{
					prop: "created_date",
					label: "Waktu",
					width: "160",
					"class-name": "body-14 col-black-2",
				},
			],
			limit_students: 30,
			page_students: 1,
			isPage_students: false,
			total_students: 0,
			total_students_topup: 0,
			search_students: "",
			students: [],
			limit_users: 10,
			page_users: 1,
			search_users: "",
			total_users: 0,
			users: [],
			userSelect: [],
			amountIsiSaldo: 0,
			dateStart: "",
			dateEnd: "",
			filterStatus: "new",
			filterTipe: "",
			roles: [],
			filterroles: "Teacher",
			classrooms: [],
			filterclasses: 0,
			page_history: 1,
			limit_history: 20,
			search_history: "",
			total_history: 0,
			cashwallet_id: "",
			transactions: [],
			transaction_detail: [],
		},
	},
	mutations: {
		changePayment(state, payload) {
			state.data = Object.assign({}, state.data, payload);
		},
		changePaymentTarikSaldoLoading(state, payload) {
			state.data.tarik_saldo_loading = payload;
		},
		changePaymentUserSelectReset(state) {
			state.data.userSelect = [];
		},
		changePaymentLoading(state, payload) {
			state.data.loading = payload;
		},
	},
	actions: {
		async getDataRfid({commit}, payload) {
			commit("changePayment", {loading: true, error: false});

			let formData = {
				rfid: payload.rfid,
			};

			const getResult = await ApiPostRequest(
				`school/cooperative/user/scan-rfid`,
				formData,
			);
			if (getResult.error) {
				Message({
					type: "error",
					message: "Gagal menampilkan data.",
				});

				commit("changePayment", {
					error: getResult.errorList || false,
					loading: false,
				});

				return false;
			} else {
				commit("changePayment", {
					loading: false,
					columns_topup: getResult.data.data,
					total_students_topup: getResult.data.data.length,
					error: false,
				});

				Message({
					type: "success",
					message: "Data berhasil ditampilkan",
				});

				return true;
			}
		},

		async getRoles({commit}) {
			const getResult = await ApiGetRequest(`school/user/roles`);

			if (getResult.error) {
				commit("changePayment", {
					loading: false,
				});

				ApiNotification(
					"error",
					"Gagal menampilkan data, mohon mencoba kembali",
				);
			} else {
				commit("changePayment", {
					loading: false,
					roles: getResult.data.data,
				});
			}
		},

		async getClassRoom({commit}) {
			const getResult = await ApiGetRequest(`school/class`);
			if (getResult.error) {
				ApiNotification(
					"error",
					"Gagal menampilkan data, mohon mencoba kembali",
				);

				commit("changePayment", {
					loading: false,
				});
			} else {
				commit("changePayment", {
					loading: false,
					classrooms: getResult.data.data,
				});
			}
		},

		async getDetailWallet({commit, state}) {
			if (state.data.loading) {
				console.log(DUPLICATE_CALL_SERVER_MESSAGE);
				return false;
			}

			commit("changePaymentLoading", true);
			const getResult = await ApiGetRequestKoperasiPayment(`wallet`);
			//const getResult = await ApiGetRequest(`http://localhost:3000/api/payment/wallet404`);
			commit("changePaymentLoading", false);

			if (getResult.error) {
				commit("changePayment", {
					wallet: [],
				});
				if (getResult.code === 500) {
					handleErrorInCooperative(getResult.code);
					return false;
				}

				if (getResult.code === 404) {
					handleErrorInCooperative(getResult.code);
					return false;
				}

				ApiNotification("error", "Gagal mengambil data, mohon coba kembali");
				return false;
			} else {
				commit("changePayment", {
					loading: false,
					wallet: getResult.data.data || [],
				});
				return true;
			}
		},

		async getListBank({commit}) {
			commit("changePaymentLoading", true);
			const getResult = await ApiGetRequest(`school/bank`);
			if (getResult.error) {
				commit("changePayment", {
					loading: false,
					bank: [],
				});
				return false;
			}
			commit("changePayment", {
				loading: false,
				bank: getResult.data.data || [],
			});
			return true;
		},

		async getAccountBank({commit, state}) {
			commit("changePayment", {
				loading: true,
			});

			const {data} = state;
			let params = {
				limit: data.limit_rekening,
				page: data.page_rekening,
				q: data.filter_rekening,
				is_approved: "all",
			};

			const getResult = await ApiGetRequest(
				`school/cooperative/bank-account`,
				params,
			);
			if (getResult.error) {
				commit("changePayment", {
					loading: false,
					account_bank: [],
				});
				if (getResult.code >= 500) {
					ApiNotification("error", SERVER_ERROR_MESSAGE);
					return false;
				}

				ApiNotification(
					"error",
					"Gagal mengambil Data Rekening, mohon coba kembali",
				);
				return false;
			}
			commit("changePayment", {
				loading: false,
				account_bank: getResult.data.data || [],
				total_rekening: (getResult.data.meta && getResult.data.meta.total) || 0,
			});
		},

		async getDetailAccountBank({commit}, bank_id = null) {
			commit("changePayment", {
				loading: true,
			});

			const getResult = await ApiGetRequest(
				`school/cooperative/bank-account/${bank_id}`,
			);
			if (getResult.error) {
				commit("changePayment", {
					loading: false,
				});

				ApiNotification("error", "Gagal mengambil data, mohon coba kembali");
			} else {
				commit("changePayment", {
					loading: false,
					detail_bank: getResult.data.data || false,
				});
			}
		},

		async hapusAccountBank({commit}, bank_id = "") {
			commit("changePayment", {
				loading: true,
			});

			const getResult = await ApiDeleteRequest(
				`school/cooperative/bank-account/${bank_id}`,
			);

			if (getResult.error) {
				commit("changePayment", {
					loading: false,
					error: getResult.errorList || getResult.error,
				});
				if (getResult.code >= 500) {
					ApiNotification("error", SERVER_ERROR_MESSAGE);
					return false;
				}

				ApiNotification("error", "Gagal menghapus data rekening");
				return false;
			}
			commit("changePayment", {
				loading: false,
				error: false,
			});

			ApiNotification("success", "Berhasil menghapus data rekening");
			return true;
		},

		async updateAccountBank({commit, state}, dataPost = {}) {
			commit("changePayment", {
				loading: true,
			});

			const {data} = state;
			const bankId = data.detail_bank && data.detail_bank.id;
			const getResult = await ApiPutRequest(
				`school/cooperative/bank-account/${bankId}`,
				dataPost,
			);

			if (getResult.error) {
				commit("changePayment", {
					loading: false,
					error: getResult.errorList || getResult.error,
				});

				ApiNotification("error", "Gagal mengubah no rekening baru");
			} else {
				commit("changePayment", {
					loading: false,
					detail_bank: false,
					error: false,
				});

				ApiNotification("success", "No rekening berhasil diubah");
			}
		},

		async createAccountBank({commit}, dataPost = {}) {
			commit("changePayment", {
				loading: true,
			});

			const getResult = await ApiPostRequest(
				`school/cooperative/bank-account`,
				dataPost,
			);
			//const getResult = await ApiPostRequest(`http://localhost:3000/api/errors400`, dataPost);

			if (getResult.error) {
				commit("changePayment", {
					loading: false,
					detail_bank: {},
					error: true,
				});
				if (getResult.code >= 500) {
					commit("changePayment", {
						error: true,
					});
					ApiNotification("error", SERVER_ERROR_MESSAGE);
					return false;
				}
				if (getResult.errorList) {
					commit("changePayment", {
						error: getResult.errorList,
					});
				}

				ApiNotification("error", "Gagal menambahkan no rekening baru");
				return false;
			}
			commit("changePayment", {
				loading: false,
				error: false,
			});

			ApiNotification("success", "No rekening baru telah ditambahkan");
			return true;
		},

		async getBalance({commit}) {
			commit("changePayment", {
				loading: true,
			});

			const getResult = await ApiGetRequest(`school/cooperative/balance`);

			if (getResult.error) {
				commit("changePayment", {
					loading: false,
				});

				ApiNotification("error", "Gagal menambahkan no rekening baru");
			} else {
				commit("changePayment", {
					loading: false,
					saldoBalance: getResult.data.data,
				});
			}
		},

		async getListTarikSaldo({commit, state}) {
			const {data} = state;
			const {dateStart, dateEnd} = data;
			let params = {
				q: data.filter_tarik_saldo || undefined,
				limit: data.limit_tarik_saldo || 10,
				page: data.page_tarik_saldo || undefined,
				bank: data.filter_bank_tarik_saldo || undefined,
				tglawal:
					dateStart !== "" ? moment(dateStart).format("YYYY-MM-DD") : undefined,
				tglakhir:
					dateEnd !== "" ? moment(dateEnd).format("YYYY-MM-DD") : undefined,
			};

			commit("changePaymentLoading", true);
			const getResult = await ApiGetRequestKoperasiPayment(
				`koperasi/withdraw`,
				params,
			);
			commit("changePaymentLoading", false);

			if (getResult.error) {
				commit("changePayment", {
					tarik_saldo: [],
					total_tarik_saldo: 0,
				});
				if (getResult.code >= 500) {
					ApiNotification("error", PAYMENT_SERVER_ERROR_MESSAGE);
					return false;
				}

				ApiNotification("error", "Gagal menampilkan Riwayat Tarik SALDO");
				return false;
			}
			commit("changePayment", {
				tarik_saldo: getResult.data.data.transactions,
				total_tarik_saldo:
					getResult.data?.total || getResult.data?.meta?.total || 0,
			});
			return true;
		},

		async canceledInqueryTarikSaldo({state}) {
			try {
				const formData = {
					transaction_id: state.data.tarik_saldo_inquery.transaction_id,
				};
				const getResult = await ApiPostRequestKoperasiPayment(
					`transaction/withdraw/cancel`,
					formData,
				);

				if (getResult.error) {
					return false;
				}
				return true;
			} catch (e) {
				console.error("canceledInqueryTarikSaldo", e);
				return false;
			}
		},
		async createInqueryTarikSaldo({commit, state}, dataPost) {
			if (state.data.tarik_saldo_loading) {
				console.log("STOP: call server duplicate");
				return false;
			}
			const formData = {
				invoice_id: "9999",
				nominal: dataPost.nominal,
				bank_account_id: dataPost.bank_account_id,
			};
			commit("changePaymentTarikSaldoLoading", true);
			const getResult = await ApiPostRequestKoperasiPayment(
				`transaction/withdraw/inquiry`,
				formData,
			);
			commit("changePaymentTarikSaldoLoading", false);

			if (getResult.error) {
				commit("changePayment", {
					error: true,
				});
				if (getResult.error.indexOf("Payment method not found") !== -1) {
					ApiNotification("error", "Tujuan Rekening Tidak ditemukan!");
					return false;
				}
				if (getResult.code >= 500) {
					ApiNotification("error", PAYMENT_SERVER_ERROR_MESSAGE);
					return false;
				}

				ApiNotification("error", getResult.error);
				return false;
			}
			commit("changePayment", {
				error: false,
				tarik_saldo_inquery: getResult.data.data,
			});
			return true;
		},

		async prosesTarikSaldo({commit, state, dispatch}, dataPost = {}) {
			if (state.data.tarik_saldo_loading) {
				console.log("STOP: call server duplicate");
				return false;
			}
			let formData = {};
			try {
				formData = {
					transaction_id: dataPost.transaction_id,
					bank_account_id: dataPost.bank_account_id,
					executor: {
						user_uuid: dataPost.user_uuid,
						user_name: dataPost.user_name,
						user_phone: dataPost.user_phone,
						user_role_name: dataPost.user_role_name,
						school_id: dataPost.school_id,
						school_name: dataPost.school_name,
					},
				};
			} catch (e) {
				console.log("prosesTarikSaldo", e);
				return false;
			}

			commit("changePaymentTarikSaldoLoading", true);
			const getResult = await ApiPostRequestKoperasiPayment(
				`transaction/withdraw/trx`,
				formData,
			);
			/*const getResult = await ApiGetRequest(
				`http://localhost:3000/api/errormessages400`,
				formData
			);*/

			commit("changePaymentTarikSaldoLoading", false);

			if (getResult.error || getResult.errorList) {
				commit("changePayment", {
					error: true,
				});

				if (Array.isArray(getResult.errorList) && getResult.errorList.length) {
					ApiNotification("error", PAYMENT_SERVER_ERROR_MESSAGE);
					return false;
				}

				let message = null;
				if (getResult.error.indexOf("not found") !== -1) {
					message =
						"Gagal melakukan Transfer SALDO, Data Sudah tidak ditemukan. Coba ulangi lagi";
				} else {
					message = `Gagal melakukan Transfer SALDO, ${getResult.error}`;
				}
				ApiNotification("error", message);
				return false;
			}
			setTimeout(() => {
				commit("changePayment", {
					loading: false,
					error: false,
				});
				ApiNotification("success", "Transaksi Berhasil");
				dispatch("getListTarikSaldo");
			}, 1500);
			return true;
		},

		async getChannel({commit}) {
			commit("changePayment", {
				loading: true,
			});

			const getResult = await ApiGetRequest(`payment/biller/getToolbar`);
			if (getResult.error) {
				commit("changePayment", {
					loading: false,
				});

				ApiNotification("error", "Gagal mengambil data, mohon coba kembali");
			} else {
				commit("changePayment", {
					loading: false,
					channel: (getResult.data.data && getResult.data.data.products) || [],
				});
			}
		},

		async topUpCheckout({commit, state}) {
			commit("changePaymentLoading", true);

			const {data} = state;

			const formData = {
				nominal: data.nominalValue,
			};
			const getResult = await ApiPostRequestKoperasiPayment(
				`transaction/topup_inq`,
				formData,
			);

			if (getResult.error) {
				commit("changePaymentLoading", false);
				if (getResult.code === 500) {
					handleErrorInCooperative(getResult.code);
					return false;
				}
				commit("changePayment", {
					loading: false,
					error: getResult.errorList || getResult.error,
				});

				ApiNotification("error", "Gagal melakukan Top Up, mohon coba kembali");
				return false;
			}

			try {
				const formData = {
					biller: 2,
					channel_method_id: data.chooseChannel.channel_method_id,
					channel_method_category: data.chooseChannel.channel_method_category,
					transaction_id: getResult.data.data.transaction_id_inquiry,
				};

				const paymentService = new PaymentApiService()
				const resultTrx = await paymentService.transactionTrxPost(formData)
				commit("changePayment", {
					error: false,
					trxCheckout: resultTrx.data.data,
					loading: false,
				});
				return true;
			} catch (e) {
				commit("changePaymentLoading", false);

				if (!e.status) {
					ApiNotification(
						"error",
						`${e.status} ${e.error}`,
					);
					return false
				}else if (e.status >= 500) {
					ApiNotification(
						"error",
						`${e.status} ${PAYMENT_SERVER_ERROR_MESSAGE}`,
					);
					return false;
				} else if (e.status >= 400 && e.status <= 499) {
					let msg = e.error;
					if (e.error.indexOf("lower or equals to") !== -1) {
						msg = "Gagal melakukan Top Up, Jika menggunakan RETAIL maksimal 5juta";
					}
					ApiNotification("error", msg);
					return false;
				}

				ApiNotification("error", PAYMENT_SERVER_ERROR_MESSAGE);

				commit("changePayment", {
					loading: false,
					error: e.errorList || e.error,
				});
				return false;
			}
		},

		async getHistoryMutasi({commit, state}, payload = null) {
			commit("changePayment", {
				loading: true,
			});

			if (payload) {
				commit("changePayment", {
					page_history: payload || state.data.page_history,
				});
			}

			commit("changePayment", {
				loading: true,
			});

			const {data} = state;

			let params = {
				limit: data.limit_history || undefined,
				page: data.page_history || undefined,
				search: data.search_history || undefined,
				status: parseInt(data.filterStatus) || 0,
				tipe: data.filterTipe || undefined,
			};

			if (
				data.dateStart &&
				data.dateStart !== "" &&
				data.dateEnd &&
				data.dateEnd !== ""
			) {
				params.date_from = moment(data.dateStart).format("YYYY-MM-DD");
				params.date_to = moment(data.dateEnd).format("YYYY-MM-DD");
			}

			const getResult = await ApiPostRequestKoperasiPayment(
				`koperasi/saldo/transaction_history`,
				params,
			);
			commit("changePayment", {
				loading: false,
			});

			if (getResult.error) {
				if (getResult.code >= 500) {
					ApiNotification("error", PAYMENT_SERVER_ERROR_MESSAGE);
					return false;
				}

				ApiNotification("error", "Gagal menampilkan Riwayat SALDO");
				return false;
			}
			const dataPembukuan = getResult.data.data;
			commit("changePayment", {
				loading: false,
				cashwallet_id: dataPembukuan.wallet_id || "",
				transactions: dataPembukuan.transactions || [],
				transaction_detail: dataPembukuan.transaction_detail || [],
				total_history: dataPembukuan.meta.total || 0,
			});
			return true;
		},

		async getMutasiPayment({commit}) {
			commit("changePayment", {
				loading: true,
			});

			const getResult = await ApiGetRequestKoperasiPayment(
				`koperasi/saldo/mutasi`,
			);
			if (getResult.error) {
				commit("changePayment", {
					loading: false,
				});

				ApiNotification("error", "Gagal mengambil data, mohon coba kembali");
			} else {
				commit("changePayment", {
					loading: false,
					mutasiPayment:
						(getResult.data.data && getResult.data.data.mutasi) || [],
				});
			}
		},
		async getSnapShootToday({commit}) {
			commit("changePayment", {
				loading: true,
			});

			const getResult = await ApiGetRequestKoperasiPayment(
				`koperasi/snapshot/today`,
			);
			if (getResult.error) {
				commit("changePayment", {
					loading: false,
				});

				ApiNotification("error", "Gagal mengambil data, mohon coba kembali");
			} else {
				commit("changePayment", {
					loading: false,
					snapShootPaymentToday: getResult.data.data || {},
				});
			}
		},
		async getSnapShoot({commit}) {
			commit("changePayment", {
				loading: true,
			});

			const getResult = await ApiGetRequestKoperasiPayment(
				`koperasi/snapshot/all`,
			);
			if (getResult.error) {
				commit("changePayment", {
					loading: false,
					snapShootPayment: [],
				});
				//ApiNotification("error", "Gagal mengambil data, mohon coba kembali");
			} else {
				commit("changePayment", {
					loading: false,
					snapShootPayment:
						(getResult.data.data && getResult.data.data.snapshots) || [],
				});
			}
		},
		async getDetailStudent({commit}, id = null) {
			commit("changePayment", {
				loading: true,
			});

			const getResult = await ApiGetRequest(`school/student/${id}`);

			if (getResult.error) {
				commit("changePayment", {
					loading: false,
				});

				ApiNotification("error", "Gagal mengambil data, mohon coba kembali");
			} else {
				commit("changePayment", {
					loading: false,
					infoUser: getResult.data.data,
				});
			}
		},

		async getListStudent({commit, state}, page = null) {
			if (page) {
				commit("changePayment", {
					page_students: page,
					isPage_students: true,
				});
			}

			commit("changePayment", {
				loading: true,
			});

			const {data} = state;
			const {search_students, page_students, limit_students} = state.data;
			let params = {
				page: page_students,
				limit: limit_students,
			};

			if (search_students) {
				params.q = search_students;
			}

			if (data.filterclasses) {
				params.class_id = data.filterclasses;
			}

			const getResult = await ApiGetRequest(
				`school/cooperative/user/student`,
				params,
			);

			if (getResult.error) {
				commit("changePayment", {
					loading: false,
				});

				ApiNotification("error", "Gagal mengambil data, mohon coba kembali");
			} else {
				commit("changePayment", {
					loading: false,
					students: getResult.data.data,
					total_students: getResult.data.meta.total,
				});
			}
		},

		async getDetailUser({commit}, id = null) {
			commit("changePayment", {
				loading: true,
			});

			const getResult = await ApiGetRequest(`school/user/${id}`);

			if (getResult.error) {
				commit("changePayment", {
					loading: false,
				});

				ApiNotification("error", "Gagal mengambil data, mohon coba kembali");
			} else {
				commit("changePayment", {
					loading: false,
					infoUser: getResult.data.data,
				});
			}
		},
		async getListUser({commit, state}, page = null) {
			if (page) {
				commit("changePayment", {
					page_users: page,
				});
			}

			commit("changePayment", {
				loading: true,
			});

			const {data} = state;
			let params = {
				page: data.page_users,
				limit: data.limit_users,
				q: data.search_users,
			};

			if (data.filterroles) {
				params.roles = data.filterroles;
			}

			const getResult = await ApiGetRequest(
				`school/cooperative/user/teacher`,
				params,
			);
			if (getResult.error) {
				commit("changePayment", {
					loading: false,
				});

				ApiNotification("error", "Gagal mengambil data, mohon coba kembali");
			} else {
				commit("changePayment", {
					loading: false,
					users: getResult.data.data,
					total_users: getResult.data.meta.total,
				});
			}
		},

		async kirimSaldo({commit, state}) {
			commit("changePayment", {
				loading: true,
			});

			const {data} = state;
			const wallet_select = [];
			if (data.userSelect && data.userSelect.length > 0) {
				data.userSelect.map((item) => wallet_select.push(item.wallet_id));
			}

			// old url payment/transaction/topup_inq
			const getResult = await ApiPostRequestKoperasiPayment(
				`koperasi/saldo/topup`,
				{
					user_ids: wallet_select,
					nominal: data.amountIsiSaldo,
				},
			);

			if (getResult.error) {
				commit("changePaymentLoading", false);
				if (getResult.code >= 500) {
					commit("changePayment", {
						error: true,
						errors: {
							server_error: [PAYMENT_SERVER_ERROR_MESSAGE],
						},
					});
					return false;
				}

				// ketika 404 maka user ada salah satu yang belum terdaftar di SALDO
				if (getResult.code === 404) {
					commit("changePayment", {
						error: true,
						errors: {
							wallet: [
								"Gagal, Terdapat pengguna yang terpilih belum terdaftar di SALDO, tolong hapus dari daftar terpilih agar proses pengiriman saldo dapat dilanjutkan",
							],
						},
					});
					return false;
				}

				commit("changePayment", {
					loading: false,
					error: true,
					errors: {
						wallet: [
							getResult.message || "Gagal melakukan Top Up, mohon coba kembali",
						],
					},
				});

				data.showFormTopup = true;
				ApiNotification("error", "Gagal melakukan Top Up, mohon coba kembali");
				return false;
			}

			const formatAmountIsiSaldo = formatNumberKoma(data.amountIsiSaldo);
			ApiNotification("success", `Topup ${formatAmountIsiSaldo} Berhasil!`);
			data.showFormTopup = false;
			commit("changePayment", {
				loading: false,
				amountIsiSaldo: 0,
				userSelect: [],
				error: false,
				errors: {},
			});
		},
	},
};

export default Payment;
