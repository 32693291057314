import axios from "axios"

// Create an axios instance
const ApiCooperativeService = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	headers: {
		Accept: "application/json",
		"Access-Control-Allow-Origin": "Authorization",
		"Content-Type": "application/json",
		"X-Requested-With": "XMLHttpRequest"
	},
	mode: "no-cors",
	withCredentials: false,
	crossdomain: true
})

// Interceptor to add token to headers
ApiCooperativeService.interceptors.request.use(
	(config) => {
		const localData = JSON.parse(localStorage.getItem("storediskola") || "null")
		const tokenKoperasi = localData?.tokenKoperasi?.token ?? ""
		if (tokenKoperasi) {
			config.headers.Origin = process.env.VUE_APP_API_URL
			config.headers.Authorization = `Bearer ${tokenKoperasi}`
		}
		return config
	},
	(error) => Promise.reject(error)
)

// Applying the error handling function to the response interceptor
ApiCooperativeService.interceptors.response.use(
	async (response) => {
		// Anda bisa melakukan pengecekan atau transformasi pada response di sini
		return response
	},
	async (error) => {
		// Anda bisa menggunakan async/await di sini jika perlu
		return handleError(error)
	},
)

// A separate function to handle global error responses
function handleError(error) {
	if (error.response) {

		const responseError = {
			code: error.code,
			status: error.response.status,
			error: error.response.data.error || error.response.data.message,
			errorList: error.response.data.errors,
		}
		if (error.response.status === 307) {
			responseError.data = error.response.data
		}
		return Promise.reject(responseError)
	} else if (error.request) {
		console.log("Error with request:", error)

		const responseError = {
			code: error.code,
			status: 0,
			error: "Cross Origin",
			errorList: null,
		}
		return Promise.reject(responseError)
	}
	return Promise.reject(error)
}


export default ApiCooperativeService
