import ApiService from "@/api/ApiService"
import axios from "axios"
import ApiCooperativeService from "@/api/ApiCooperativeService"

class PaymentApiRepository {
	constructor() {
		this.apiService = ApiService
		this.apiCooperativeService = ApiCooperativeService
		this.cancelTokenSource = null
		this.mock = 'http://localhost:3000/api'
	}

	createCancelToken() {
		if (this.cancelTokenSource) {
			this.cancelTokenSource.cancel("Operation canceled due to new request.")
		}
		this.cancelTokenSource = axios.CancelToken.source()
	}

	async postTransactionDepositInq(formData) {
		return await this.apiCooperativeService.post(`/payment/transaction/topup_inq`, formData)
	}

	async postTransactionDepositTrx(formData) {
		return await this.apiCooperativeService.post(`/payment/transaction/topup_trx`, formData)
	}

}

export default new PaymentApiRepository()
